.hide {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.full-width {
  width: 100%;
}

h5.ant-typography {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

.ant-btn {
  height: unset;

  &:hover {
    opacity: 0.8;
  }
}

.button-confirm:disabled,
.button-cancel[disabled] {
  background: $color_valencia;
  color: $color_athens_gray_2;

  &:hover,
  &:active,
  &:focus {
    background: $color_valencia;
    border: 2px solid $color_valencia;
    color: $color_athens_gray_2;
  }
}

.ant-tooltip-inner {
  max-width: 520px;
}

.button-cancel:disabled,
.button-cancel[disabled] {

  &:hover,
  &:active,
  &:focus {
    background: transparent;
    border-color: $color_valencia;
    color: $color_valencia;
  }

  background: transparent;
  border-color: $color_valencia;
  color: $color_valencia;
}

[ant-click-animating-without-extra-node="true"]::after,
.ant-click-animating-node {
  display: none;
}

%error-text-common {
  color: $color_french_rose;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1px;
  text-shadow: 0px 32px 64px rgba(51, 54, 60, 0.15);
  padding-top: 8px;
}

.textEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.name-nft {
  width: 150px;
}

.d-flex {
  display: flex;
  gap: 12px;
  align-items: center;
}

.mgb-12px {
  margin-bottom: 12px;
}

.mgb-0px {
  margin-bottom: 0px !important;
}

.white-space-nomal {
  white-space: normal;
}

.form-item {
  .error-text {
    @extend %error-text-common;
  }
}

.error-text-custom {
  @extend %error-text-common;
}

.text-valencia {
  color: $color_valencia !important;
}

.text-la-palma {
  color: $color_la_palma !important;
}

.button {
  min-width: 180px;
  border-radius: 20px;
  border: 2px solid $color_valencia;
  height: 43px;
  font-weight: 600;
  transition: none;

  &-confirm {
    background: $color_valencia;
    color: $color_athens_gray_2;

    &:hover,
    &:active,
    &:focus {
      background: $color_valencia;
      border: 2px solid $color_valencia;
      color: $color_athens_gray_2;
    }
  }

  &-cancel {
    color: $color_valencia;

    &:hover,
    &:active,
    &:focus {
      background: transparent;
      border-color: $color_valencia;
      color: $color_valencia;
    }
  }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
}

.rounded-button {
  background: $color_pink_gradient;
  border-radius: 90px;
  height: 40px;
  font-weight: 700;
  color: $color_white;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:active,
  &:focus {
    background: $color_pink_gradient;
    color: $color_white;
  }

  & svg {
    margin-right: 8px;
  }
}

.rounded-blank-button {
  background: transparent;
  border-radius: 90px;
  height: 40px;
  font-weight: 700;
  color: $color_text;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:active,
  &:focus {
    background: transparent;
    border: 1px solid $color_valencia;
    color: $color_text;
  }

  & svg {
    margin-right: 8px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.image-thumbnail {
  width: 28px;
  height: 28px;
  object-fit: cover;
}

.error-text {
  @extend %error-text-common;
}

.mgb-20 {
  margin-bottom: 20px;
}