.user-management-wrap {
  .ant-table-wrapper {
    table {
      .action-user {
        display: flex;
        column-gap: 6px;
        align-items: center;

        button {
          margin-bottom: 4px;
        }

        svg {
          cursor: pointer;
        }
        img {
          margin-bottom: 4px;
        }
      }
      .active {
        color: #009332;
        margin-bottom: 0;
      }
      .inactive {
        color: $color_placeholder;
      }
    }
  }
  .search {
    .ant-input-affix-wrapper {
      max-width: 327px;
      background: #fcfcfc;
      border: 1px solid $color_athens_gray;
      box-sizing: border-box;
      border-radius: 20px;
      float: right;
      margin-bottom: 28px;

      path {
        fill: $color_valencia;
      }
    }
  }
}

.user-detail-wrap {
  padding: 40px;
  background-color: $color-white;
  p {
    margin-bottom: 0;
  }
  .header-user-detail {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .title {
      margin-right: 24px;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
    }
    button {
      background-color: #a22727;
      height: 32px;
      border-radius: 8px;
      padding: 2px 30px;
      color: white;
    }
  }

  .date {
    display: flex;
    column-gap: 102px;

    .access,
    .last-updated {
      color: #212121;
      font-size: 12px;

      .title {
        color: #605f5f;
        font-weight: 600;
        margin-right: 12px;
      }
    }
  }

  .info {
    display: flex;
    margin-bottom: 28px;
    column-gap: 100px;

    .item {
      label {
        font-size: 16px;
        color: $color_black;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 6px;
      }

      .price,
      .amount {
        color: $color_scorpion;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
      }
    }
  }

  .nft {
    display: block;
    .title {
      color: $color_black;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 28px;
      font-size: 16px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      &__right {
        button {
          border: none;
        }
        button:disabled,
        button[disabled] {
          &:hover,
          &:active,
          &:focus {
            background: transparent;
            border-color: $color_valencia;
            color: $color_valencia;
          }
          background: transparent;
          border-color: $color_valencia;
          color: $color_valencia;
        }
      }
    }

    .list-nft {
      margin-bottom: 28px;

      .item {
        img {
          width: 100%;
          object-fit: cover;
          border-radius: 8px;
          aspect-ratio: 1/1.15;
        }

        &:last-child {
          margin-right: 0px;
        }

        .name {
          font-weight: 700;
          margin-top: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 112px;
          display: inline-block;
        }
      }
    }
  }

  .active-log {
    .title {
      color: #605f5f;
      font-weight: 600;
      line-height: 22px;
      margin-bottom: 28px;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 20px;
    height: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
  }
}
