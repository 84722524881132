.register-nft {
  &__header {
    display: flex;
    margin-bottom: 20px;
  }
  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    color: $color_black;
    margin-left: 16px;
  }
  &__action {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  &__body {
    background-color: $color-white;
    padding: 40px;
  }
  &__form {
    flex-basis: 50%;
    .form-item {
      margin-bottom: 1.5rem;
    }
  }
  &__preview {
    display: flex;
    justify-content: center;
    &__title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }
    &__image {
      width: 440px;
      height: 440px;
      border-radius: 5px;
      border: 1px solid $color_athens_gray;
      display: flex;
      justify-content: center;
      align-items: center;
      .ant-image {
        max-width: 440px;
        height: 440px;
        border-radius: 5px;
        border: 1px solid $color_athens_gray;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
      video {
        border-radius: 5px;
      }
      .react-player__preview {
        border-radius: 5px;
      }
    }
  }
  &__discard {
    @extend .rounded-blank-button;
    width: 200px;
  }
  &__get {
    @extend .rounded-button;
    width: 200px;
  }
}
