.ant-menu:not(.ant-menu-horizontal) {
  .ant-menu-item {
    &:hover {
      background: $color_pink_gradient;
      color: $color_white;

      path,
      rect {
        fill: $color_white;
      }
    }

    &-selected {
      background-color: transparent;
    }
  }
}

.ant-select-dropdown {
  background: $color_white;
  // box-shadow: 0px 16px 64px rgba(217, 64, 54, 0.1);
  border-radius: 10px;

  .ant-select-item {
    line-height: 22px;
    letter-spacing: -0.01em;
    color: $color_raven;
    font-weight: 600;
    padding: 8px 0;
    margin: 0 8px;

    &:hover {
      background-color: transparent;
      color: $color_valencia;
    }

    // &:not(:last-child) {
    //   border-bottom: 1px solid $color_athens_gray;
    // }
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: transparent;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $color_white;
  }

  .ant-empty-text {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.table {
  width: 100%;

  .ant-table-wrapper {
    overflow: hidden;
    border: 1px solid $color_alto;
    background-color: $color_white;
    border-radius: 16px;
    font-weight: 600;
  }

  .ant-table-thead {
    .ant-table-cell {
      background-color: $color_text;
      color: $color_white;
      letter-spacing: -0.01em;
      padding-top: 10px;
      padding-bottom: 10px;
      line-height: 21px;

      &:before {
        display: none;
      }
    }

    th {
      font-weight: 700;
    }

    th.ant-table-column-has-sorters:hover {
      background-color: $color_text;
    }

    .ant-table-column-sorters .ant-table-column-sorter path {
      fill: $color_white;
    }
  }

  .ant-table-column-sorters {
    justify-content: flex-start;

    .ant-table-column-title {
      flex: none;
      margin-right: 8px;
    }

    .ant-table-column-sorter {
      path {
        fill: $color_col_gray;
        opacity: 0.5;
      }

      .active {
        path {
          opacity: 1;
        }
      }
    }
  }

  .ant-table-tbody {
    .ant-table-cell {
      color: $color_scorpion;
      font-weight: 400;
    }
  }

  .ant-pagination-options {
    .ant-select-selector {
      padding: 4px 12px !important;
      height: auto !important;
      border-radius: 24px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px !important;
    }
  }

  .ant-table-empty {
    .ant-table-content {
      overflow: hidden !important;
    }
  }

  .ant-empty-text {
    padding: 64px 0;

    .nodata-text {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: $color_scorpion;
    }

    p {
      font-weight: 400;
      margin-top: 20px;
    }
  }
}

.ant-tabs {
  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-tab {
    padding-bottom: 12px;

    .ant-tabs-tab-btn {
      color: $color_nobel;
      text-shadow: none;
      font-weight: 600;
      letter-spacing: -0.01em;
    }

    &-active {
      .ant-tabs-tab-btn {
        color: $color_valencia;
      }
    }
  }

  .ant-tabs-ink-bar {
    background: $color_valencia;
  }
}

.ant-descriptions {
  border: 1px solid $color_alto;
  border-radius: 16px;

  &-header {
    border-bottom: 1px solid $color_alto;
    margin-bottom: 0;
    padding: 16px 24px 14px;
  }

  &-view {
    padding: 20px 24px 12px;
  }
}

.ant-input {
  &-search.ant-input-search-with-button {
    .ant-input-group .ant-input-affix-wrapper {
      padding-right: 4px;
    }
  }
}

.form-item {
  .ant-input-affix-wrapper-disabled {
    background-color: $color_grey;
  }
}

// Remove input background color for Chrome auto complete

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}