.password-reset {
  &__logo {
    margin: auto;
    display: block;
    width: 51px;
    height: auto;
  }

  &__title {
    margin-bottom: 32px !important;
  }

  &__description {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: $color_scorpion;
    margin-top: 36px;
    text-align: center;
  }

  .form-item {
    margin-top: 22px;

    &__input {
      margin-top: 8px;
    }

    &__button-submit {
      display: block;
      width: 100%;
      max-width: 426px;
      margin: 44px auto 0;
    }

    &__link {
      padding-left: 2px;

      span {
        text-decoration: underline;
      }
    }
  }

  .resend-container {
    margin-top: 10px;
    font-size: 10px;
    line-height: 16px;
    font-weight: 600;
    // letter-spacing: 1.5px;
    color: $color_scorpion;
  }
}
