.change-password-wrap {
  display: flex;
  align-items: center;
  height: 100%;

  .change-password {
    background: #ffffff;
    border: 1px solid $color_valencia;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    width: 420px;
    margin: 0px auto;
    padding: 32px 30px;

    .title-change-pass {
      text-align: center;
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
    }
    .label-password {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
    }
    .form-item {
      &__input {
        margin-top: 20px;
      }

      &__button-submit {
        margin-top: 25px;
        line-height: 16px;
      }
    }
  }
}
