.nft-detail {
  &__pop {
    cursor: pointer;
  }

  &__title {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  &__delete {
    @extend .rounded-blank-button;
    color: $color_secondary;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__body {
    background-color: $color-white;
    padding: 40px;
    display: grid;
    column-gap: 2rem;
    grid-template-columns: 1fr 1fr;

    &__title {
      font-size: 20px;
      line-height: 32px;
      font-weight: 700;
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__property {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 6px;
    }

    &__image {
      max-width: 440px;
      max-height: 440px;
      display: flex;
      justify-content: center;
      align-items: center;

      .ant-image {
        max-height: 440px;

      }

      .react-player__preview {
        border-radius: 5px;
      }

      img {
        max-width: 100%;
        max-height: 440px;
        width: auto;
        height: auto;
        border-radius: 5px;
        object-fit: cover;
      }
    }

    .pool-number {
      color: $color_primary_blue;
      text-decoration: underline;
    }
  }
}