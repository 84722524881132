.ant-table {
  .align-center {
    .ant-table-column-sorters {
      justify-content: center;
    }
  }

  .align-right {
    .ant-table-column-sorters {
      justify-content: flex-end;
    }

    .content-price {
      .value {
        justify-content: flex-end;
      }
    }
  }

  .ant-table-cell {
    .status {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      max-width: 96px;
      font-size: 12px;
    }
  }

  .pool-name {
    width: 168px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.my-pagination {
  .ant-pagination {
    display: flex;
    align-items: center;
    margin-top: 32px;

    .ant-pagination-total-text {
      font-size: 12px;
      padding: 8px 12px;
      line-height: 16px;
      font-weight: 600;
      color: $color_mine_shaft;
      border-radius: 24px;
      border: 1px solid $color_alto;
      height: unset;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      path {
        fill: $color_scorpion;
      }
    }

    &-disabled {
      .button-pagination {
        path {
          fill: $color_nobel;
        }
      }
    }

    .button-pagination {
      background: transparent;
      border: none;
      height: unset;
      line-height: unset;
      box-shadow: none;
    }

    .ant-pagination-prev {
      margin-left: auto;
      height: unset;
    }

    .ant-pagination-next {
      height: unset;
    }

    .ant-pagination-item {
      a {
        fill: $color_scorpion;

        &:hover {
          color: $color_valencia;
        }
      }
    }

    .ant-pagination-item-active {
      border-radius: 50%;
      border-color: $color_valencia;
      box-sizing: border-box;
      background-color: $color_valencia;

      a {
        color: $color_white;
        font-weight: 600;

        &:hover {
          color: $color_white;
        }
      }
    }

    &-options {
      .ant-pagination-options-quick-jumper {
        line-height: 32px;

        input {
          padding: 2px 14px;
          height: unset;
          width: 60px;
          border-radius: 24px;
        }
      }
    }
  }
}
