.login {
  &__logo {
    margin: auto;
    display: block;
    width: 51px;
    height: auto;
  }

  &__title {
    margin-bottom: 32px !important;
  }

  .form-login {
    margin-top: 36px;
  }

  .label-password {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      color: #2755a2;
    }

    .remember {
      span {
        color: #292d34;
      }
    }
  }

  .form-item {
    margin-bottom: 20px;

    &__input {
    }

    &__button-submit {
      margin-top: 44px;
      line-height: 16px;
    }
  }
}
