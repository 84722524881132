.header {
  background-color: $color_white;
  border-bottom: 1px solid $color_athens_gray;
  padding: 0 24px;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);

  &__right {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .ant-select {
    margin-right: 34px;

    .ant-select-selector {
      border: none;
      align-items: center;
    }

    &-selection-item {
      display: flex;
      align-items: center;
    }

    .flag {
      margin-right: 10px;
    }
  }

  .ant-badge {
    margin-right: 34px;

    &:hover {
      cursor: pointer;
    }

    &-status {
      line-height: 0;
    }

    &-dot {
      width: 8px;
      height: 8px;
    }
  }

  &__profile {
    border: 2px solid $color_valencia;
    background-color: rgba(217, 64, 54, 0.1);
    border-radius: 90px;
    color: $color_text;
    font-weight: 500;
    padding: 4px 12px 4px 4px;
    display: flex;
    align-items: center;

    &:hover,
    &:active,
    &:focus {
      border: 2px solid $color_valencia;
      background-color: rgba(217, 64, 54, 0.1);
      color: $color_text;
    }

    .username {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100px;
    }
  }

  .ant-avatar {
    margin-right: 10px;
  }
}

.select-dropdown-language {
  .flag {
    margin-right: 8px;
  }
}

.user-dropdown {
  background-color: $color_white;
  padding: 24px 18px 24px;
  box-shadow: 0px 16px 64px rgba(31, 47, 70, 0.2);
  border-radius: 10px;

  &__profile {
    display: flex;
    align-items: center;
  }

  &__left {
    margin-right: 10px;
  }

  &__right {
    .username {
      color: $color_col_gray;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }

    .email {
      font-size: 12px;
      line-height: 19px;
      color: $color_scorpion;
    }
  }

  .ant-menu {
    border-right: none;

    &-item {
      padding-left: 8px;
      display: flex;
      align-items: center;
      font-weight: 600;
      color: $color_scorpion;
      height: 36px;
    }
  }
}

.list-notification {
  background-color: $color_white;
  box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding-bottom: 10px;
  width: 400px;
  display: table;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .header {
    padding: 15px 15px;
    font-weight: bold;
    font-size: 17px;
    border-radius: 10px 10px 0px 0px;
  }
  .list-noti-body {
    display: flex;
    flex-direction: column;
    .notification-item {
      display: flex;
      align-items: center;
      padding: 0px 20px;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
      .notfication-box {
        // border-top: 0.1px solid rgba(0, 0, 0, 0.1);
        width: 400px;
        margin-left: 10px;
        border-bottom: 0.1px solid rgba(0, 0, 0, 0.1);
        padding: 10px 10px;
        color: $color-shuttle-gray;
        .title {
          font-weight: bold;
        }
      }
    }
  }
}

.walletconnect-modal__mobile__toggle {
  display: none !important;
}
