.switch-wrap {
  border: 1px solid #6f22e4;
  border-radius: 20px;

  .ant-radio-group {
    background: #ffffff;
    border-radius: 22px;

    .ant-radio-button-wrapper {
      background: #ffffff;
      color: #6f22e4;
      border: none;
      // height: 32px;
      border-radius: 20px;

      span {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .ant-radio-button-wrapper-checked {
      background: #4b00ea;
      border: none;
      border-radius: 20px;
      z-index: 0;
      color: #ffffff;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background: transparent;
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      background: transparent;
    }
  }
}
