.token-wrap {
  .token-container {
    height: 100%;
    .title {
      font-weight: 700;
      font-size: 32px;
    }
    .token {
      &__header {
        display: flex;
        justify-content: space-between;
        .search {
          width: fit-content;
          flex: none;
          min-width: 0;
          margin-left: auto;
          margin-right: 0;
          > button {
            @extend .rounded-button;
          }
        }
      }
    }
    .list-currency {
      .currency-block {
        overflow: hidden;
        border: 1px solid $color_placeholder;
        border-radius: 10px;
        max-height: 300px;
        display: flex;
        flex-direction: column;
        &__header {
          background-color: $color_text;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          height: 40%;
          padding: 12px 0 12px 20px;
          & p {
            font-weight: 700;
            color: $color_white;
            margin: 0;
          }
        }
        &__body {
          padding: 12px 20px;
          overflow: auto;
          flex: 1;
          padding-bottom: 0;
          .icon-delete {
            margin-left: 16px;
          }
        }
        &:first-child {
          margin-bottom: 18px;
        }
      }
      .currency-item {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        padding: 4px 4px 4px 0px;
        border-bottom: 1px solid $color_placeholder;
        & > div {
          display: flex;
          align-items: center;
        }
        &:last-child {
          margin-bottom: 0;
          border: none;
        }
        .currency-name {
          min-width: 200px;
          font-weight: 700;
          margin: 0;
        }
        .currency-value {
          border: 1px solid $color_placeholder;
          border-radius: 5px;
          padding: 4px 8px;
          width: 120px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .edit {
          margin-left: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
.ant-modal-content {
  .form-token {
    display: flex;
    justify-content: center;
    .form-item {
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__label {
        font-weight: 600;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: $color_col_gray;
        margin-bottom: 9px;
        text-align: center;
      }

      &__input {
        padding: 14px 10px;
        border-radius: 8px;
        border: 1px solid $color_alto;
        background-color: $color_white;
        width: 190px;
      }
      & .error-text {
        text-align: center;
      }
    }
    button {
      display: block;
      margin: 0 auto;
    }
  }
  .add-token {
    .form-item {
      margin-top: 16px;
    }
    .info-token {
      display: flex;
      background-color: $color-silver;
      margin-bottom: 16px;
      max-width: 520px;
      padding: 20px 15px;
      justify-content: space-between;
      margin-top: 16px;
      border-radius: 12px;

      .title {
        font-weight: 700;
      }
      .content {
        position: relative;
        height: 32px;
      }

      .ant-spin {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    // .choose-token {
    //   display: flex;
    //   gap: 12px;
    //   .form-item {
    //     flex: 1;
    //     max-width: 350px;
    //     margin-bottom: 0;
    //     .select-token {
    //     }
    //   }
    //   .button-verify {
    //     flex: 1;
    //     min-width: 0;
    //     margin-bottom: 0;
    //     margin-top: auto;
    //   }
    // }
    button {
      @extend .rounded-button;
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .group-button {
      display: flex;
      gap: 20px;
      .button-cancel {
        @extend .rounded-blank-button;
        width: 100%;
      }
      .button-add-token {
        @extend .rounded-button;
        width: 100%;
        margin-bottom: 0px;
        &:disabled {
          opacity: 0.6;
        }
      }
    }
  }
}

.token-detail {
  background: $color_white;
  // border: 3px solid $color_royal_blue;
  box-sizing: border-box;
  border-radius: 25px;
  padding: 40px 34px;

  .btn-remove {
    max-width: 392px;
    width: 100%;
    // background: $color_persian_red;
    background: linear-gradient(96.23deg, #912066 0%, #c52b8b 75.46%);
    color: $color_white;
    font-size: 18px;
    font-weight: 700;
    border-radius: 20px;
  }

  p {
    margin: 0px;
  }

  .token {
    &-name {
      font-size: 28px;
      font-weight: 700;
      color: $color_black;
      margin-bottom: 12px;
    }
    &-added {
      display: flex;
      margin-bottom: 22px;
      p {
        margin-right: 56px;
        &:last-child {
          margin: 0px;
        }
        font-weight: 700;
        font-size: 12px;
        .content {
          font-weight: 400;
        }
      }
    }
    &-info {
      margin-bottom: 42px;
      p {
        display: flex;
        margin-bottom: 18px;
        font-weight: 700;
        font-size: 18px;
        .title {
          flex-basis: 130px;
        }
        .content {
          margin-left: 56px;
          font-weight: 400;
        }
      }
    }
  }
}
