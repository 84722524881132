.pool-management-wrap {
  .ant-table-wrapper {
    table {
      .action-user {
        display: flex;
        column-gap: 6px;
        align-items: center;

        .ant-switch {
          margin-bottom: 4px;
          background: #452438;

          &-checked {
            background: $color_pink_gradient;
          }
        }

        svg {
          cursor: pointer;
        }

        img {
          margin-bottom: 4px;
        }
      }
    }
  }

  .pool-type {
    margin-bottom: 20px;

    &__name {
      font-size: 20px;
      line-height: 32px;
      font-weight: 700;
      color: $color_placeholder;
      transition: 0.25s ease-in-out;

      &:hover {
        cursor: pointer;
        color: $color_black;
      }

      &:first-child {
        margin-right: 24px;
      }

      &--active {
        color: $color_black;
      }
    }
  }

  .header-pool {
    margin-bottom: 28px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 32px;

    >button {
      @extend .rounded-button;
    }
  }

  .search {
    justify-content: flex-start;
  }
}

.action-pool {
  &__head {
    display: flex;
    margin-bottom: 20px;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin-left: 16px;
  }

  &__body {
    background-color: $color-white;
    padding: 40px;

    &__tabs {
      margin-bottom: 32px;
    }

    &__tab {
      font-size: 16px;
      color: $color_placeholder;
      font-weight: 700;
      border-radius: 5px;
      padding-top: 8px;
      padding-bottom: 8px;

      &:nth-child(2) {
        margin-left: 16px;
      }

      &:hover,
      &:active,
      &:focus,
      &--active {
        color: $color_valencia;
        border-color: $color_valencia;

        &:disabled {
          border-color: $color_valencia;
          color: $color_valencia;
        }
      }
    }

    .ant-space-item {
      display: flex;
    }

    .reward-rate {
      margin-bottom: 9px;

      &__title {
        line-height: 22px;
        font-weight: 600;
        margin: 0;
      }
    }

    .equal {
      text-align: center;
      transform: translateY(8px);
    }

    .reward-rule {
      .ant-space {
        margin-bottom: 16px;
      }

      &__title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        margin: 0;
      }

      &__label {
        font-weight: 700;
        padding-bottom: 8px;
        display: block;
      }

      .form-item {
        margin-bottom: 16px;
      }
    }

    .nft-rewards {
      margin-bottom: 16px;

      &__title {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: $color_black;
        margin: 0;
      }

      .add-nft {
        @extend .rounded-button;
      }

      &__head {
        display: flex;
        margin-bottom: 24px;
        margin-top: 8px;

        &__total {
          font-weight: 500;
        }
      }

      &__table {
        .table {
          margin-bottom: 16px;

          .name {
            width: 150px;
            white-space: normal;
          }
        }

        .select-tier {
          .ant-select-selector {
            border-radius: 5px;
          }
        }
      }
    }

    .create-pool,
    .update-pool {
      padding-left: 12px;

      button {
        margin-right: 12px;
      }
    }
  }

  form {
    .form-item {
      margin-bottom: 32px;
      width: 100%;
      max-width: 520px;
    }

    .group-btn {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      column-gap: 16px;

      button {
        min-width: auto;
        height: 32px;
      }

      .btn-publish {
        background-color: #6f22e4;
        color: #ffffff;
        border-radius: 8px;
        display: flex;
        align-items: center;
        column-gap: 8px;

        path {
          fill: #ffffff;
        }
      }
    }

    .select-publish {
      .ant-select-selector {
        padding: 5px 11px !important;
      }
    }
  }
}

.pool-detail {
  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin-left: 16px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &__left {
      display: flex;
    }

    &__right {
      display: flex;
      justify-content: flex-end;

      .switch-button {
        display: flex;
        align-items: center;
        margin-right: 20px;

        >p {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 0;
          margin-right: 12px;
          color: $color_scorpion;
        }

        .ant-switch {
          margin-bottom: 4px;
          background: $color_livid_brown;

          &-checked {
            background: $color_pink_gradient;
          }
        }
      }
    }
  }

  &__body {
    background-color: $color-white;
    padding: 40px;

    .ant-space-item {
      display: flex;
    }
  }

  .edit-pool {
    @extend .rounded-blank-button;
    margin-left: 12px;
  }

  .download-button {
    @extend .rounded-blank-button;
  }

  .delete-pool {
    @extend .rounded-blank-button;
    color: $color_secondary;
    margin-left: 12px;
  }

  .pool-name {
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 14px;

    >p {
      width: fit-content;
      margin-bottom: 6px !important;
    }

    p {
      max-width: 1008px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      margin: 0px;
    }

    .status {
      margin-top: 2px;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      color: $color_text;
      display: flex;
      align-items: center;
    }
  }

  .date-wrap {
    display: grid;
    grid-template-columns: 230px auto;
    column-gap: 50px;
    row-gap: 12px;
    align-items: center;

    >p {
      color: #212121;
      font-size: 12px;
      font-weight: 500;
      margin: 0;

      .title {
        color: #605f5f;
        font-size: 12px;
        font-weight: 700;
        margin-right: 12px;
      }
    }
  }

  .contract {
    margin-top: 24px;
    margin-bottom: 16px;

    label {
      font-size: 16px;
      color: $color_black;
      font-weight: 700;
    }

    .content {
      font-size: 16px;
      font-weight: 700;
      color: #2755a2;
      display: flex;
      align-items: center;
      column-gap: 8px;

      path {
        fill: #2755a2;
      }
    }
  }

  .info-wrap {
    display: grid;
    grid-template-columns: 150px 150px 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 16px;
    justify-content: flex-start;
    margin-bottom: 24px;
  }

  .item {
    label {
      font-size: 16px;
      line-height: 24px;
      color: $color_black;
      font-weight: 700;
      margin-bottom: 6px;
    }

    .content {
      color: $color_text;
      margin: 0;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .reward-usd {
      margin: 0;
    }
  }

  .reward-rule {
    margin-bottom: 24px;
    background-color: $color_grey;
    padding: 24px 44px;

    .ant-space {
      margin-bottom: 6px;
    }

    &__title {
      font-weight: 700;
      font-size: 16px;
      margin: 0;
    }

    .tier {
      font-weight: 700;
    }
  }

  .withdraw-nft {
    padding-left: 0;
    color: $color_valencia;
    font-weight: 700;
  }

  .deposit-nft {
    font-weight: 700;
    color: $color_primary_blue;
  }

  .list-nft {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 16px;

    &__total {
      font-weight: 500;
    }
  }

  .table {
    .name-nft {
      white-space: normal;
    }
  }
}

.loading-pool-detail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #00000073;
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}