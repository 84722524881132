.layout-public {
  min-height: 100vh;
  background: white;
  overflow: hidden;
  // position: relative;

  .ant-layout-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container-sm {
    position: relative;
    width: 100%;
    max-width: 420px;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 32px 30px;
    border: 1px solid $color_valencia;

    .circle-1 {
      position: absolute;
      top: 40px;
      left: 0;
      transform: translateX(-60%);
      background: linear-gradient(132.96deg,
          #6f22e4 -2.89%,
          rgba(217, 64, 54, 0.498469) 42.36%,
          rgba(217, 64, 54, 0) 93.19%);
      backdrop-filter: blur(4px);
      opacity: 0.3;
      width: 190px;
      height: 190px;
      border-radius: 50%;
      z-index: -1;
    }

    .circle-2 {
      position: absolute;
      left: 42px;
      bottom: 0;
      transform: translateY(-100%);
      background: linear-gradient(132.96deg,
          #6f22e4 -2.89%,
          rgba(217, 64, 54, 0.498469) 42.36%,
          rgba(217, 64, 54, 0) 93.19%);
      backdrop-filter: blur(4px);
      opacity: 0.3;
      width: 94px;
      height: 94px;
      border-radius: 50%;
      z-index: -1;
    }

    .circle-3 {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(40%, 10%) rotate(135deg);
      background: linear-gradient(132.96deg,
          #6f22e4 -2.89%,
          rgba(217, 64, 54, 0.498469) 42.36%,
          rgba(217, 64, 54, 0) 93.19%);
      backdrop-filter: blur(4px);
      opacity: 0.3;
      width: 350px;
      height: 350px;
      border-radius: 50%;
      z-index: -1;
    }
  }

  .form-item {
    &__label {
      font-weight: 600;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: $color_col_gray;
    }

    &__input {
      padding: 8px 10px;
      border-radius: 8px;
      border: 1px solid $color_alto;
      background-color: $color_white;
    }

    &__link {
      color: $color_valencia;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      // letter-spacing: 1.5px;
      text-decoration: underline;
    }

    &__button-submit {
      padding: 10px 0;
      font-weight: 600;
      color: $color_white;
      background-color: $color_valencia;
      border-radius: 8px;
      border: none;

      &.ant-btn-loading {
        background-color: $color_nobel;
        color: $color_white;
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:focus,
      &[disabled]:active {
        background-color: $color_nobel;
        color: $color_white;
        cursor: not-allowed;
      }

      span {
        line-height: 16px;
      }
    }
  }
}

.layout-private {
  .ant-layout {
    background-color: $color_white;
    overflow-x: hidden;
    height: 100vh;
  }

  .ant-layout-sider-children {
    background-color: $color_white;

    button {
      path {
        fill: #6f22e4;
      }
    }
  }

  .ant-menu {
    padding-top: 60px;
    background-color: $color_white;
    position: relative;

    .close-icon {
      position: absolute;
      top: 12px;
      right: 18px;
      cursor: pointer;
    }

    .button-collapse-slider {
      position: absolute;
      top: 12px;
      left: 50%;
      transform: translateX(-50%);
      border: none;
      box-shadow: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      path {
        fill: $color_valencia;
      }
    }

    &-item {
      color: $color_placeholder;
      letter-spacing: -0.01em;
      font-weight: 700;

      &:not(:first-child) {
        margin-top: 18px;
      }

      .ant-menu-title-content {
        margin-left: 8px;
      }

      svg {
        width: 24px;
      }

      path {
        fill: $color_nobel;
      }
    }

    &-item-selected,
    .item-selected {
      color: $color_white;
      background: $color_pink_gradient;

      path,
      rect {
        fill: $color_white;
      }

      &:after {
        display: none;
      }
    }

    &-submenu {
      margin-top: 18px;

      &-title {
        span {
          color: $color_nobel;
          letter-spacing: -0.01em;
          font-weight: 600;
          margin-left: 10px;
        }

        .ant-menu-submenu-arrow {
          color: $color_nobel;
        }
      }

      .ant-menu-sub {
        margin-top: 10px;
      }

      &-selected {
        .ant-menu-submenu-title {
          span {
            color: $color_valencia;
          }

          path {
            fill: $color_valencia;
          }
        }

        .ant-menu-title-content {
          color: $color_valencia;
          opacity: 0.8;
        }
      }
    }
  }

  .ant-layout-sider {
    border-right: 1px solid $color_athens_gray;
  }

  .sider {
    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 64px;
      border-bottom: 1px solid $color_athens_gray;
      background-color: $color_white;
      padding: 0 24px;
      position: relative;

      .logo-page {
        color: $color_valencia;
        font-family: $font-DMSan-Regular;
        font-size: 18px;
        font-weight: 700;
        font-style: italic;
        line-height: 23px;

        img {
          margin-right: 5px;
        }
      }

      &:after {
        content: "";
        position: absolute;
        right: -1px;
        top: 0;
        bottom: 0;
        border-right: 1px solid $color_white;
      }
    }
  }

  .ant-layout-sider-collapsed {
    .ant-menu-item {
      padding: 12px !important;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      border-radius: 50%;
      width: 34px;
      height: 34px;

      .ant-menu-title-content {
        display: none;
      }

      &-selected {
        &::before {
          display: none;
        }

        background-color: $color_valencia;

        path,
        rect {
          fill: $color_white;
        }

        &:hover {
          opacity: 0.8;

          path {
            fill: $color_white;
          }
        }
      }
    }
  }

  .ant-layout-content {
    background-color: #fafafa;
    padding: 56px 40px 40px 40px;

    @media (max-width: $breakpoint_large) {
      padding: 24px 18px 18px 18px;
    }

    overflow-y: scroll;
  }
}

.public-layout-2 {
  .container-sm {
    max-width: 648px;
    padding: 44px 58px;
  }
}

.form-item {

  // margin-bottom: 32px;
  &__label {
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: $color_col_gray;
    margin-bottom: 9px;
  }

  &__input {
    padding: 8px 10px;
    border-radius: 8px;
    border: 1px solid $color_alto;
    background-color: $color_white;
  }

  &__link {
    color: $color_valencia;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    // letter-spacing: 1.5px;
    text-decoration: underline;
  }

  &__button-submit {
    padding: 10px 0;
    font-weight: 600;
    color: $color_white;
    background-color: $color_valencia;
    border-radius: 8px;
    border: none;

    &.ant-btn-loading {
      background-color: $color_nobel;
      color: $color_white;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      background-color: $color_nobel;
      color: $color_white;
      cursor: not-allowed;
    }

    span {
      line-height: 16px;
    }
  }

  .select {
    // border: 1px solid #D0D0D0;
    box-sizing: border-box;
    border-radius: 8px;

    .ant-select-selection-item {
      font-size: 14px;
      letter-spacing: -0.01em;
      color: inherit;
      font-weight: 400;
    }

    .ant-select {
      width: 100%;
    }

    .ant-select-selector {
      border-radius: 8px;
      height: auto;
      padding: 4px 10px;
    }

    &.ant-select .ant-select-selector {
      border-color: $color_athens_gray;
      border-radius: 24px;
      padding: 0 14px;
    }

    path {
      fill: $color_scorpion;
    }
  }
}