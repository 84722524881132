.modal-confirm {
  .ant-modal-content {
    background-color: #ffffff;
    .ant-modal-body {
      p {
        margin: 0px;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        color: #23262f;
      }
      .content {
        color: $color_light_mode;
        text-align: center;
        margin: 22px 0px;
        font-size: 16px;
      }

      .group-btn {
        display: flex;
        column-gap: 20px;
        .btn-confirm {
          @extend .rounded-button;
          color: #ffffff;
          height: 44px;
        }
        .btn-cancel {
          @extend .rounded-blank-button;
          height: 44px;
        }
        button {
          min-width: 182px;
          padding: 12px 16px;
          border-radius: 8px;
          font-weight: 600;
        }
      }
    }
  }
}
