.ant-modal-root {
  .ant-modal-content {
    background: $color_white;
    border-radius: 20px;

    .connect_wallet {
      padding: 12px 8px 24px 8px;

      h1 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        margin-bottom: 12px;
        text-align: center;
        color: $color_text;
      }

      p {
        line-height: 24px;
        text-align: center;
        color: $color_text;
        max-width: 300px;
        margin: 0px auto;
      }

      .btn {
        display: flex;
        background: $color_pink_gradient;
        height: 60px;
        align-items: center;
        width: 100%;
        position: relative;
        padding: 12px 23px;
        border-radius: 8px;
        border: none;
        line-height: 24px;
        font-size: 16px;
        font-weight: 600;
        color: $color_white;
        margin-top: 28px;

        &:last-child {
          margin-top: 8px;
        }

        img {
          margin-right: 12px;
        }

        &__icon-right {
          position: absolute;
          right: 12px;
        }
      }
    }
  }

  .ant-modal-close {
    top: 25px;
    right: 25px;
  }
}

.ant-modal-confirm {
  &-title {
    div {
      text-align: center;
    }
  }

  &-content {
    div {
      text-align: center;
    }
  }

  .ant-modal-confirm-btns {
    float: none;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    column-gap: 16px;

    .ant-btn {
      min-width: 120px;
      border-radius: 24px;
      font-weight: 500;
      border-color: $color_valencia;
      border-width: 2px;
      color: $color_valencia;

      &+.ant-btn {
        background-color: $color_valencia;
        color: $color_white;
      }
    }
  }
}

.model-metamask_notfound {
  .ant-modal-content {
    background-color: #ffffff;
  }

  .network_notice {
    text-align: center;
    padding: 32px 0;

    .title {
      font-size: 32px;
      line-height: 40px;
      font-weight: 600;
      margin-top: 32px;

      &.ant-typography {
        margin-bottom: 16px;
      }
    }

    .ant-spin-dot {
      font-size: 80px;
    }

    a {
      cursor: default;
    }
  }
}

.model-metamask_notfound {
  .ant-modal-content {
    background: $color_white;
  }

  .metamask_notfound {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 600;
      color: #23262f;
    }

    >img {
      max-width: 65px;
      margin-bottom: 30px;
    }

    >a {
      color: #3772ff;
      display: inline-flex;
      align-items: center;

      >svg {
        margin-right: 5px;
      }
    }

    .ant-spin-lg {
      .ant-spin-dot {
        font-size: 80px;
      }
    }
  }
}

.get-metadata {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    font-size: 16px;
    text-align: center;
    margin-top: 1rem;
  }
}

.modal-action {
  display: flex;
  justify-content: center;
  column-gap: 2rem;

  &__cancel {
    @extend .rounded-blank-button;
    width: 164px;
  }

  &__confirm {
    @extend .rounded-button;
    width: 164px;
  }
}

.withdraw-nft-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: $color_light_mode;
  padding: 0 24px;

  img {
    margin-bottom: 16px;
    max-width: 220px;
    max-height: 220px;
    width: auto;
    height: auto;
    border-radius: 5px;
  }

  &__name {
    margin-bottom: 16px;
    width: 100%;
    white-space: normal;
    text-align: center;
  }

  &__form {
    width: 100%;

    .form-item {
      margin-bottom: 16px;
    }
  }

  .amount {
    display: flex;
    justify-content: space-between;
  }

  .button-max {
    @extend .rounded-button;
    height: 32px;
  }

  .ant-input-affix-wrapper {
    padding: 4px 10px;
  }

  // .ant-input-group-wrapper {
  //   .ant-input-wrapper {
  //     .ant-input {
  //       border: none;
  //     }
  //   }
  // }
  &__action {
    display: flex;
    justify-content: center;
    column-gap: 24px;
    padding-top: 16px;
  }

  .button-cancel {
    width: 164px;
    @extend .rounded-blank-button;
  }

  .button-withdraw {
    width: 164px;
    @extend .rounded-button;
  }
}

.network {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  img {
    margin-right: 8px;
  }
}

.select-nft-modal {
  .ant-modal {
    width: 80%;
    max-width: 900px;
    min-width: 700px;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 12px;

    .ant-switch {
      &-checked {
        background: $color_pink_gradient;
      }
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    column-gap: 24px;
    margin-top: 24px;

    &__cancel {
      width: 164px;
      @extend .rounded-blank-button;
    }

    &__confirm {
      width: 164px;
      @extend .rounded-button;
    }
  }
}