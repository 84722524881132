.nft-reward {
  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    color: $color_black;
  }

  .search {
    justify-content: flex-start;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .search {
      align-items: center;
    }
  }

  &__register {
    justify-content: space-between;
    @extend .rounded-button;
    width: 139px;
  }

  .registered {
    font-weight: 500;
    color: $color_yellow;
  }

  .deposited {
    font-weight: 500;
    color: $color_green;
  }

  .out-of-stock {
    font-weight: 500;
    color: $color_red;
  }
}