.dashboard {
  &-group {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 12px;
    column-gap: 42px;
    margin: 60px 0px;

    @media (max-width: $breakpoint_large) {
      column-gap: 12px;
    }

    .item {
      background: #ffffff;
      box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      padding: 16px 12px;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin-bottom: 12px;

        &:last-child {
          margin: 0px;
        }
      }

      .amount {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
      }

      .name {
        line-height: 24px;
        color: $color_valencia;
        font-weight: 700;
      }

      .difference {
        font-weight: 700;
        display: flex;
        align-items: center;
        line-height: 16px;

        svg {
          margin-right: 2px;
        }
      }
    }
  }

  .group-btn {
    display: flex;
    flex-flow: wrap;
    gap: 13px;

    &__item {
      padding: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border: 2px solid $color_silver_chalice;
      border-radius: 12px;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: $color_scorpion;
      min-width: 68px;
      height: 46px;

      &:focus,
      &:hover {
        border: 2px solid $color_disco;
        color: $color_disco;
      }

      img {
        width: 28px;
        height: 26px;
      }
    }

    .active {
      border: 2px solid $color_disco;
      color: $color_disco;
    }
  }
}