.search {
  display: flex;
  flex: 1;
  min-width: 500px;
  align-items: center;
  column-gap: 12px;

  .search-input {
    height: 40px;
    max-width: 387px;
    background: #fcfcfc;
    border: 1px solid $color_athens_gray;
    box-sizing: border-box;
    border-radius: 20px;
    float: right;

    path {
      fill: $color_valencia;
    }
  }

  .refresh-icon {
    cursor: pointer;
    margin-right: 12px;
    min-width: 21px;
    max-width: 22px;
    width: 100%;
  }

  .search-select {
    border-radius: 20px;

    // border: 1px solid $color_athens_gray;
    .ant-select-selector {
      border-radius: 20px;
      height: 40px;
      min-width: 120px;

      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
      }
    }

    .ant-select-item {
      margin: 0;
      padding: 8px;
    }
  }
}