@import "_variables";
@import "_common.scss";
@import "_override";
@import "layout.scss";

// Components
@import "components/loading.scss";
@import "header";
@import "footer";
@import "components/table.scss";
@import "components/modal.scss";
@import "components/search.scss";
// @import "components/text.scss";
// @import "components/modal-transaction-details.scss";
@import "components/nodata.scss";
@import "components/switchComponent.scss";
@import "components/loadingContract.scss";
@import "components/modalConfirm.scss";

// Pages
@import "login.scss";
@import "forgot-password.scss";
@import "password-reset.scss";
@import "change-password.scss";
@import "dashboard.scss";
@import "token.scss";
@import "page/user.scss";
@import "page/pool.scss";
@import "page/nft.scss";
@import "page/registerNft.scss";
@import "page/nft-detail.scss";
body {
  font-family: $font-DMSan-Regular, "Poppins", sans-serif;
}
