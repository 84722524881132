.forgot-password {
  &__logo {
    margin: auto;
    display: block;
    width: 51px;
    height: auto;
  }

  &__title {
    margin-bottom: 32px !important;
  }

  &__description {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: $color_scorpion;
    margin-top: 36px;
    text-align: center;
  }

  .form-item {
    margin-top: 22px;

    &__input {
      margin-top: 8px;
    }

    &__button-submit {
      margin-top: 44px;
    }

    &__link {
      padding-left: 2px;
    }
  }

  .link-container {
    text-align: center;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    svg {
      fill: $color_valencia;
      path {
        stroke: $color_valencia;
      }
    }
  }
}
