$breakpoint_small: 640px;
$breakpoint_medium: 1024px;
$breakpoint_large: 1280px;
$breakpoint_extra_large: 1920px;

$font-Noir-Pro-Regular: "Noir-Pro";
$font-DMSan-Regular: "DMSans-Regular";
@font-face {
  font-family: $font-Noir-Pro-Regular;
  src: url("../../src/resources/fonts/NoirStd-Regular.ttf");
}
@font-face {
  font-family: $font-DMSan-Regular;
  src: url("../../src/resources/fonts/DMSans-Regular.ttf");
}
$color-white: #fff;
$color-black: #000;
$color-primary: #272a31;
$color-muddy-waters: #bc8f58;
$color-baltic-sea: #181619;
$color-dusty-gray: #959595;
$color-shuttle-gray: #666a74;
$color-azure-radiance: #1181f1;
$color-burnt-sienna: #ef5350;
$color-seance: #9c27b0;
$color-alabaster: #f8f8f8;
$color-mandalay: #ae7d1f;
$color-gray-6: #f2f2f2;
$color-gallery: #ededed;

$color-silver: #c4c4c4;

$color_col_gray: #1a1919;
$color_alto: #d0d0d0;
$color_valencia: #c52b8b;
$color_scorpion: #212121;
$color_nobel: #b5b5b5;
$color_french_rose: #ef466f;
$color_athens_gray: #e6e8ec;
$color_sider: #fff3f280;
$color_chablis: #fff3f2;
$color_raven: #777e90;
$color_mine_shaft: #212121;
$color_la_palma: #1dbc10;
$color_thunderbird: #bc1010;
$color_fire_bush: #e69b39;
$color_charade: #23262f;
$color_athens_gray_2: #fcfcfd;
$color_bright_turquoise: #00c2ff;

//new color
$color_text: #060a13;
$color_pink_gradient: linear-gradient(96.23deg, #912066 0%, #c52b8b 75.46%);
$color_placeholder: #c9c9c9;
$color_secondary: #d13724;
$color_light_mode: #4f5566;
$color_grey: #f5f5f5;
$color_primary_blue: #3742e9;
$color_stroke: #dee0e4;
$color_yellow: #c08f0e;
$color_green: #009332;
$color_red: #ff0000;
$color_livid_brown: #452438;
$color_silver_chalice: #b1b1b1;
$color_scorpion: #5f5f5f;
$color_disco: #912066;
